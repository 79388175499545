import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export default function GlobalLoading () {
    const  isLoading  = useSelector(state => state.loading.isLoading)
    if (!isLoading) { return null }
    return (
      <div className='text-center loadWrapper d-flex justify-content-center align-items-center flex-column loadingImage'>
        <span className='loader'>
        </span>
      </div>
    )
  }

GlobalLoading.propTypes = {
  loading: PropTypes.bool
}
