import React from 'react'
import OwlCarousel from 'react-owl-carousel3'
import ImageAssets from '../../utils/ImageAssests'

const bannerSlider = {
    loop: true,
    margin: 0,
    nav: false,
    mouseDrag: false,
    touchDrag: true,
    dots: true,
    navText: [],
    autoplay: true,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1
        },
        1440: {
            items: 1
        }
    }
}
export default function Banner() {
    return (
        <OwlCarousel className='banner-carousel owl-carousel owl-theme'
            {...bannerSlider}>
            <div className='banner-main-panel'>
                <div className='banner-image'>
                    <div className='banner-img'><img src={ImageAssets.common.bannerImageOne} alt='banner' className='img-responsive' /></div>
                    <div className='banner-description'>
                        <div className='mb-20'> <span className='font-40 font-700 text-white banner-heading'> New Horizons Student Services</span></div>
                        <div>
                            <span className='font-16 text-white'>
                                New Horizons Student Services provides honest expert advice to all potential aspirants
                                seeking higher education in Canada. We take pride in our ability to work closely with the
                                community with utmost integrity, transparency, and honesty.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-main-panel'>
                <div className='banner-image'>
                    <div className='banner-img'><img src={ImageAssets.common.bannerImageTwo} alt='banner' className='img-responsive' /></div>
                    <div className='banner-description'>
                        <div className='mb-20'> <span className='font-40 font-700 text-white banner-heading'> New Horizons Student Services</span></div>
                        <div>
                            <span className='font-16 text-white'> New Horizons Student Services provides honest expert advice to all potential aspirants
                                seeking higher education in Canada. We take pride in our ability to work closely with the
                                community with utmost integrity, transparency, and honesty.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-main-panel'>
                <div className='banner-image'>
                    <div className='banner-img'><img src={ImageAssets.common.bannerImageThree} alt='banner' className='img-responsive' /></div>
                    <div className='banner-description'>
                        <div className='mb-20'> <span className='font-40 font-700 text-white banner-heading'> New Horizons Student Services</span></div>
                        <div>
                            <span className='font-16 text-white'> New Horizons Student Services provides honest expert advice to all potential aspirants
                                seeking higher education in Canada. We take pride in our ability to work closely with the
                                community with utmost integrity, transparency, and honesty.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </OwlCarousel>
    )
}
