import React, { useState } from "react";
import Header from "../common/Header";
import Banner from "../common/Banner";
import Footer from "../common/Footer";
import Button from "../common/Button";
import { Messages } from "../../constants/StaticTexts";
import OwlCarousel from "react-owl-carousel3";
import { appConfig } from "../../constants/AppConfig";
// import { ErrorMessages } from '../../constants/ErrorMessages'
// import validator from 'validator'
// import { mobileNumberFormat } from '../../utils/Utils'
import { Link } from "react-router-dom";
import { UIRoutes } from "../../constants/FrontendRoutes";

// const studentTestimonial = {
//   loop: true,
//   margin: 20,
//   nav: false,
//   mouseDrag: false,
//   touchDrag: true,
//   dots: false,
//   autoplay: true,
//   smartSpeed: 2000,
//   stagePadding: 10,
//   autoplayHoverPause: false,
//   center: false,
//   navText: [],
//   animateOut: 'fadeOut',
//   animateIn: 'fadeIn',
//   responsive: {
//     0: {
//       items: 1
//     }
//   }
// }
const respScreen = {
  loop: false,
  margin: 20,
  nav: true,
  mouseDrag: false,
  touchDrag: true,
  dots: false,
  autoplay: false,
  smartSpeed: 1500,
  navSpeed: 1500,
  stagePadding: 10,
  autoplayHoverPause: false,
  center: false,
  navText: [],
  animateOut: "fadeOut",
  animateIn: "fadeIn",
  responsive: {
    0: {
      items: 2,
    },
    575: {
      items: 1,
    },
    576: {
      items: 3,
    },
  },
};

export default function Home() {
  // const [name, setName] = useState({ value: '', error: null })
  // const [email, setEmail] = useState({ value: '', error: null })
  // const [contact, setContact] = useState({ value: '', error: null })
  // const [message, setMessage] = useState({ value: '', error: null })

  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  // const onInputChange = (inputId) => (event) => {
  //   if (inputId === InputIds.NAME) {
  //     if (event.target.value) {
  //       setName({ value: event.target.value, error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.NAME_NOT_VALID })
  //     } else {
  //       setName({ value: '', error: null })
  //     }
  //   } else if (inputId === InputIds.EMAIL) {
  //     if (event.target.value) {
  //       setEmail({ value: event.target.value, error: validator.isEmail(event.target.value) ? null : ErrorMessages.EMAIL_NOT_VALID })
  //     } else {
  //       setEmail({ value: '', error: null })
  //     }
  //   } else if (inputId === InputIds.CONTACT) {
  //     if (event.target.value) {
  //       const formatedNumber = mobileNumberFormat(event.target.value)
  //       if (formatedNumber) {
  //         setContact({ value: formatedNumber, error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID })
  //       }
  //     } else {
  //       setContact({ value: '', error: null })
  //     }
  //   } else if (inputId === InputIds.MESSAGE) {
  //     if (event.target.value) {
  //       setMessage({ value: event.target.value, error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.MESSAGE.MIN, max: appConfig.MAX_LENGTH.MESSAGE.MAX }) ? null : ErrorMessages.MESSAGE_NOT_VALID })
  //     } else {
  //       setMessage({ value: '', error: null })
  //     }
  //   }
  // }
  return (
    <div>
      <Header page={Messages.HOME} />
      <Banner />
      <div className="home-page-main-wrapper" id="home-page-main-wrapper">
        <div className="home-page-content">
          <div className="our-services-wrapper container-padding">
            <div className="row align-items-center no-gutters">
              <div className="col-lg-12 col-xl-5">
                <div className="our-services-left-content">
                  <div className="our-services-heading">
                    <div className="heading-class">
                      {" "}
                      <h2 className="gray-color">
                        Our<span className="text-primary-color"> Services</span>{" "}
                      </h2>{" "}
                    </div>
                  </div>
                  <div className="our-services-description mt-20">
                    <p className="descripton-text gray-color">
                      {" "}
                      We understand the challenges of moving to a new country as
                      a student. Our services are aimed to provide a robust
                      support system for international students coming to Canada
                      and make their transition as easy as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-7  align-items-center">
                {width >= 1200 && (
                  <div className="our-services-right-content text-center position-relative">
                    <div className="row justify-content-center flex-row-wrap">
                      {appConfig.OUR_SERVICES.map((eachServices, index) => {
                        return (
                          <div className="col-sm-4" key={index}>
                            <div className="image-card mt-30">
                              <div className="image-wrapper">
                                {" "}
                                <img
                                  src={eachServices.IMG}
                                  alt="study abroad"
                                  className="img-resp"
                                />{" "}
                              </div>
                              <div className="text-center mt-20">
                                {" "}
                                <span className="font-20 image-text text-primary-color">
                                  {eachServices.NAME}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {width <= 1199 && width >= 320 && (
                  <div className="our-services-right-content text-center position-relative">
                    <div className="flex-row-wrap">
                      <OwlCarousel
                        className=" owl-carousel owl-theme"
                        {...respScreen}
                      >
                        {appConfig.OUR_SERVICES.map((eachServices, index) => {
                          return (
                            <div className="" key={index}>
                              <div className="image-card">
                                <div className="image-wrapper">
                                  {" "}
                                  <img
                                    src={eachServices.IMG}
                                    alt="study abroad"
                                    className="img-resp"
                                  />{" "}
                                </div>
                                <div className="text-center mt-20">
                                  {" "}
                                  <span className="font-20 image-text text-primary-color">
                                    {eachServices.NAME}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                    <div className="left-arrow-content position-absolute"></div>
                    <div className="right-arrow-content position-absolute"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className='student-testimonial-wrapper mt-30'>
            <div className='student-testimonial-content h-100 container-padding'>
              <div className='row h-100 no-gutters'>
                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 my-auto'>
                  <div className='student-testimonial-heading'>
                    <div className='mb-20 student-title'> <h2 className='font-600 text-white'> Student Testimonials </h2></div>
                    <div className='mb-20 student-subtitle'> <span className='text-white font-18 font-700'> What our students say about New Horizon Student Services  </span></div>
                    <div className='student-description'> <span className='text-white'> In publishing and graphic design, Lorem ipsum is a placeholder
                      text commonly used to demonstrate the visual form of a document or a typeface
                      without relying on meaningful content. Lorem ipsum may be used as a placeholder
                      before final copy is available. It is also used to temporarily replace text in a process called greeking,
                      which allows designers to consider the form of a webpage or publication, without the meaning of the text
                      influencing the design. </span></div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 my-auto'>
                  <OwlCarousel
                    className=" owl-carousel owl-theme"
                    {...studentTestimonial}
                  >
                    <div className='student-testimonial-description position-relative'>
                      <div className='mb-40 student-card-text position-relative'>
                        <span className='student-info text-italic black-color font-600'>
                          "Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua."
                        </span>
                      </div>
                      <div className='student-name d-flex align-items-center'>
                        <div className='student-image'>
                          <img src={ImageAssets.home.studentImage} alt='student' />
                        </div>
                        <div className='student-name-content pl-10'>
                          <div> <span className='black-color font-700 font-18'> John Doe </span> </div>
                          <div> <span className='black-color university-name'> Student, University of Toronto </span> </div>
                        </div>
                      </div>
                      <div className='dot-symbol-image position-absolute'>
                        <img src={ImageAssets.common.dotedSymbole} alt='doted symbole' />
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='our-success-wrapper container-padding'>
            <div className='map-pattern-block'><img src={ImageAssets.home.lookingForVisaSection} alt='looking-for-visa' className='img-fluid' /></div>
            <div className='our-success-content'>
              <div className='row no-gutters'>
                <div className='col-sm-12 col-lg-12 col-xl-5 col-xxl-6 order'>
                  <div className='our-success-section h-100'>
                    <div className='heading-class'><h2 className='gray-color'>Your Success is, <div><span className='text-primary-color mt-5'>Our Success</span></div></h2></div>
                    <div className='section-description'>
                      <div className='row no-gutters'>
                        <div className=' col-6 col-sm-3 col-md-3 col-lg-3 col-xl-6'>
                          <div className='description-block'>
                            <div className='block-image'><img src={ImageAssets.home.studentYourSuccess} alt='student' className='resp-image' /></div>
                            <div className='numbers mt-10'><span className='font-32 gray-color font-600'>870+</span></div>
                            <div className='mt-10  name'><span className='font-18 gray-color font-600'>Students</span></div>
                          </div>
                        </div>
                        <div className='col-6 col-sm-3 col-md-3 col-lg-3 col-xl-6'>
                          <div className='description-block'>
                            <div className='block-image'><img src={ImageAssets.home.successfullVisaYourSuccess} alt='successful-visa' className='resp-image' /></div>
                            <div className='numbers mt-10'><span className='font-32 gray-color font-600'>500+</span></div>
                            <div className='name mt-10'><span className='font-18 gray-color font-600'>Successful Visa</span></div>
                          </div>
                        </div>
                        <div className='col-6 col-sm-3 col-md-3 col-lg-3 col-xl-6'>
                          <div className='description-block'>
                            <div className='block-image'><img src={ImageAssets.home.immigrationsYourSuccess} alt='immigrations' className='resp-image' /></div>
                            <div className='numbers mt-10'><span className='font-32 gray-color font-600'>800+</span></div>
                            <div className='mt-10 name'><span className='font-18 gray-color font-600'>Immigrations</span></div>
                          </div>
                        </div>
                        <div className='col-6 col-sm-3 col-md-3 col-lg-3 col-xl-6'>
                          <div className='description-block'>
                            <div className='block-image'><img src={ImageAssets.home.universitiesYourSuccess} alt='universities' className='resp-image' /></div>
                            <div className='numbers mt-10'><span className='font-32 gray-color font-600'>50+</span></div>
                            <div className='mt-10 name'><span className='font-18 gray-color font-600'>Universities</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-lg-12 col-xl-7 col-xxl-6 padding-space'>
                  <div className='looking-for-visa-section'>
                    <div className='looking-for-visa-block'>
                      <div className='section-heading'>
                        <div className='heading-class'> <h2 className='gray-color'>Looking for<span className='text-primary-color ml-2'>Visa Approval?</span> </h2> </div>
                      </div>
                      <div className='form-field'>
                        <div className='name-input'>
                          <TextField
                            fullWidth
                            label={Labels.FULL_NAME}
                            // id='fullWidth'
                            onChange={onInputChange(InputIds.NAME)}
                            value={name.value}
                          />
                          {name && name.error && <div className='badge badge-danger'>{name.error}</div>}
                        </div>
                        <div className='email-input'>
                          <TextField
                            fullWidth
                            label={Labels.EMAIL}
                            // id='fullWidth'
                            onChange={onInputChange(InputIds.EMAIL)}
                            value={email.value}
                          />
                          {email && email.error && <div className='badge badge-danger'>{email.error}</div>}
                        </div>
                        <div className='contact-number-input'>
                          <TextField
                            fullWidth
                            label={Labels.PHONE_NUMBER}
                            // id='fullWidth'
                            onChange={onInputChange(InputIds.CONTACT)}
                            value={contact.value}
                          />
                          {contact && contact.error && <div className='badge badge-danger'>{contact.error}</div>}
                        </div>
                        <div className='message-input'>
                          <TextField
                            fullWidth
                            label={Labels.MESSAGE}
                            // id='fullWidth'
                            multiline
                            rows={4}
                            onChange={onInputChange(InputIds.MESSAGE)}
                            value={message.value}
                          />
                          {message && message.error && <div className='badge badge-danger'>{message.error}</div>}
                        </div>
                        <div className='button-wrapper'>
                          <Button
                            type='submit'
                            className={'btn btn-primary submit-now'}
                            text={Messages.APPLY_ONLINE} >
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="inquiry-form-wrapper container-padding">
            <div className="inquiry-form-section text-center ">
              <div className="inquiry-text-block">
                <h2 className="font-32 font-600 inquiry-text dark-gray-color">
                  “Get in touch with us by completing the initial inquiry form”
                </h2>
              </div>
              <div className="button-wrapper mt-60">
                <Link to={UIRoutes.CONTACTUS}>
                  <Button
                    type="submit"
                    className={"btn btn-primary submit-now"}
                    text={Messages.CONTACT_US}
                  ></Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
