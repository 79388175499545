import React from "react";
import { Labels, Messages } from "../../constants/StaticTexts";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import { UIRoutes } from "../../constants/FrontendRoutes";
import { useForm } from "react-hook-form";
import Form from "../common/Form";

function Signup() {
  const reactFormContext = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div>
      <div className="new-signup-wrapper">
        <div className="contect-content container-padding">
          <div className="signup-section">
            <Form reactFormContext={reactFormContext} onSubmit={onSubmit}>
              <div className="signup-box">
                <div className="text-center signup-text">
                  <span className="signup-text">{Messages.SIGNUP}</span>
                </div>
              </div>
              <div className="form-details">
                <div className="Form-block">
                  <div className="email-input">
                    <Form.Input name="FastName" label={Labels.FIRST_NAME} />
                  </div>
                </div>
                <div className="Form-block">
                  <div className="password-input">
                    <Form.Input name="LastName" label={Labels.LAST_NAME} />
                  </div>
                </div>
                <div className="Form-block">
                  <div className="password-input">
                    <Form.Input name="Email" label={Labels.EMAIL} />
                  </div>
                </div>
                <div className="Form-block">
                  <div className="password-input">
                    <Form.Input
                      name="PhoneNumber"
                      label={Labels.PHONE_NUMBER}
                    />
                  </div>
                </div>
                <div className="singupLink-block">
                  <div>
                    <span className="singup-text">Back to</span>
                    <Link to={UIRoutes.LOGIN} className="text-red">
                      {Messages.LOGIN}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <Button
                  type="submit"
                  className={"btn btn-primary submit-now"}
                  text={Messages.SIGNUP}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
