import React from "react";
import { Labels, Messages } from "../../constants/StaticTexts";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import { UIRoutes } from "../../constants/FrontendRoutes";
import { useForm } from "react-hook-form";
import Form from "../common/Form";

function Login() {
  const reactFormContext = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div>
      <div className="new-login-wrapper">
        <div className="contect-content container-padding">
          <div className="login-section">
            <Form reactFormContext={reactFormContext} onSubmit={onSubmit}>
              <div className="login-box">
                <div className="text-center login-text">
                  <span className="login-text">{Messages.LOGIN}</span>
                </div>
              </div>
              <div className="form-details">
                <div className="Form-block">
                  <div className="email-input">
                    <Form.Input name="Email" label={Labels.EMAIL} />
                  </div>
                </div>
                <div className="singupLink-block">
                  <div>
                    <span className="singup-text">Not have an account?</span>
                    <Link to={UIRoutes.SIGNUP} className="text-red">
                      {Messages.SIGNUP}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <Button
                  type="submit"
                  className={"btn btn-primary submit-now"}
                  text={Messages.SEND_OTP}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div className="new-login-wrapper">
        <div className="contect-content container-padding">
          <div className="login-section">
            <Form reactFormContext={reactFormContext} onSubmit={onSubmit}>
              <div className="login-box">
                <div className="text-center login-text">
                  <span className="login-text">{Messages.LOGIN}</span>
                </div>
              </div>
              <div className="form-details">
                <div className="Form-block">
                  <div className="otp-input">
                    <Form.Input name="otp" />
                    <Form.Input name="otp" />
                    <Form.Input name="otp" />
                    <Form.Input name="otp" />
                    <Form.Input name="otp" />
                    <Form.Input name="otp" />
                  </div>
                </div>
                <div className="singupLink-block">
                  <div>
                    <span className="singup-text">Didn't receive the OTP?</span>
                    <Link to={UIRoutes.SIGNUP} className="text-red">
                      Resend OTP
                    </Link>
                  </div>
                </div>
              </div>
              <div className="button-wrapper otp-button-wraper">
                <Button
                  type="submit"
                  className={"btn btn-primary submit-now"}
                  text={Messages.LOGIN}
                />
                <Button
                  type="submit"
                  className={"btn btn-secondary submit-now"}
                  text={"Back"}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
