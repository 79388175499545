import React from "react";
import Home from "./component/home/Home";
import About from "./component/about/About";
import "./component/css/bootstrap.min.css";
import "./component/scss/_styles.scss";
import Contact from "./component/contact/Contact";
import Service from "./component/service/Service";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UIRoutes } from "./constants/FrontendRoutes";
import Blog from "./component/blog/Blog";
import ScrollToTop from "./interceptors/ScrollToTop";
import store from "./store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalLoading from "./component/common/GlobalLoading";
import Login from "./component/auth/Login";
import Signup from "./component/auth/Signup";
// import StepperForm from "./component/form-stepper/StepperForm";

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path={UIRoutes.HOME} element={<Home />}></Route>
            <Route exact path={UIRoutes.ABOUTUS} element={<About />}></Route>
            <Route exact path={UIRoutes.SERVICES} element={<Service />}></Route>
            <Route
              exact
              path={UIRoutes.CONTACTUS}
              element={<Contact />}
            ></Route>
            <Route exact path={UIRoutes.BLOG} element={<Blog />}></Route>
            <Route exact path={UIRoutes.LOGIN} element={<Login />}></Route>
            <Route exact path={UIRoutes.SIGNUP} element={<Signup />}></Route>
            {/* <Route
              exact
              path={UIRoutes.STEPPERFORM}
              element={<StepperForm />}
            ></Route> */}
          </Routes>
          <GlobalLoading />
        </ScrollToTop>
      </Router>
      <ToastContainer
        autoClose={5000}
        position={"top-right"}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </Provider>
  );
}
if (process.env.NODE_ENV === "production")
  console.log = function no_console() {};
