import React from 'react'
import Button from '../common/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
// import ImageAssets from '../../utils/ImageAssets'
import { Messages } from '../../constants/StaticTexts'
import ImageAssets from '../../utils/ImageAssests'

export default function ContactUsPopup(props) {
  const { open, onCloseClick } = props
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        <div className="d-flex justify-content-end cancel-btn">
          <img src={ImageAssets.common.closePopupIcon} alt="close-icon" className='img-responsive' onClick={onCloseClick}></img>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="popup-icon-block">
          <div className="d-flex justify-content-center right-image-block">
            <img src={ImageAssets.common.popupImage} alt="popup" className='img-responsive'></img>
          </div>
        </div>
        <div className="title-panel mt-30 text-center">
          <span className="title-panel-text font-600 font-18 ">
            Thank you for submitting the request. Our team member will contact you soon.
          </span>
        </div>
        <div className="button-panel mt-20 text-center">
          <Button
            type='submit'
            className={'btn btn-primary ok'}
            text={Messages.OK}
            onClick={onCloseClick}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}


ContactUsPopup.propTypes = {}

