import React from 'react'

export default function CommonBanner(props) {
    const { img, description } = props
    return (
        <div>
            <div className='banner-main-panel'>
                <div className='banner-image'>
                    <div className='banner-img'><img src={img} alt="banner" className='img-responsive' /></div>
                    <div className='banner-description'>
                        <div className='mb-20'> <span className='font-40 font-700 text-white banner-heading'> New Horizons Student Services</span></div>
                        <div>
                            <span className='font-16 text-white'>
                                {description}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
