import React, { useState } from "react";
import { TextField } from "@mui/material";
import Button from "../common/Button";
import { Messages, InputIds, Labels } from "../../constants/StaticTexts";
import Header from "../common/Header";
import CommonBanner from "../common/CommonBanner";
import { appConfig } from "../../constants/AppConfig";
import Footer from "../common/Footer";
import validator from "validator";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { mobileNumberFormat } from "../../utils/Utils";
import MenuItem from "@mui/material/MenuItem";
import { contactUsAction } from "../../actions/CommonActions";
import ContactUsPopup from "../screen-popup/ContactUsPopup";

export default function Contact(props) {
  const [firstName, setFirstName] = useState({ value: "", error: null });
  const [lastName, setLastName] = useState({ value: "", error: null });
  const [email, setEmail] = useState({ value: "", error: null });
  const [age, setAge] = useState({ value: "", error: null });
  const [contact, setContact] = useState({ value: "", error: null });
  const [maritalStatus, setMaritalStatus] = useState({
    value: "",
    error: null,
  });
  const [completedEducation, setCompletedEducation] = useState({
    value: "",
    error: null,
  });
  const [workExperience, setWorkExperience] = useState({
    value: "",
    error: null,
  });
  const [spouseFirstName, setSpouseFirstName] = useState({
    value: "",
    error: null,
  });
  const [spouseLastName, setSpouseLastName] = useState({
    value: "",
    error: null,
  });
  const [spouseAge, setSpouseAge] = useState({ value: "", error: null });
  const [spouseEducation, setSpouseEducation] = useState({
    value: "",
    error: null,
  });
  const [referenceName, setReferenceName] = useState({
    value: "",
    error: null,
  });
  const [comment, setComment] = useState({
    value: "",
    error: null,
  });
  const [openPopup, setOpenPopup] = useState(false);

  const onCloseClick = () => {
    setOpenPopup(!openPopup);
  };

  const onSuccess = (res = undefined) => {
    setFirstName({ value: "", error: null });
    setLastName({ value: "", error: null });
    setEmail({ value: "", error: null });
    setAge({ value: "", error: null });
    setContact({ value: "", error: null });
    setMaritalStatus({ value: "", error: null });
    setCompletedEducation({ value: "", error: null });
    setWorkExperience({ value: "", error: null });
    setSpouseFirstName({ value: "", error: null });
    setSpouseLastName({ value: "", error: null });
    setSpouseAge({ value: "", error: null });
    setSpouseEducation({ value: "", error: null });
    setReferenceName({ value: "", error: null });
    setComment({ value: "", error: null });
    if (res !== undefined) {
      setOpenPopup(true);
    }
  };

  const onContactUsClick = () => {
    let requestBody = {
      firstName: firstName.value,
      lastName: lastName.value,
      age: age.value,
      contactNumber: contact.value,
      email: email.value,
      completedEducation: completedEducation.value,
      maritalStatus: maritalStatus.value,
      workExperienceFiled: workExperience.value,
      referenceName: referenceName.value,
      comment: comment.value,
    };
    if (maritalStatus.value === appConfig.MARITAL_STATUS[1]) {
      let spouse = {
        firstName: spouseFirstName.value,
        lastName: spouseLastName.value,
        age: spouseAge.value,
        completedEducation: spouseEducation.value,
      };

      requestBody = { ...requestBody, spouse };
    }
    contactUsAction(requestBody, (res, err) => onSuccess(res, err));
  };

  const onInputChange = (inputId) => (event) => {
    if (inputId === InputIds.FIRST_NAME) {
      if (event.target.value) {
        setFirstName({
          value: event.target.value,
          error: validator.isLength(event.target.value, {
            min: appConfig.MAX_LENGTH.NAME.MIN,
            max: appConfig.MAX_LENGTH.NAME.MAX,
          })
            ? null
            : ErrorMessages.FIRST_NAME_NOT_VALID,
        });
      } else {
        setFirstName({ value: "", error: null });
      }
    } else if (inputId === InputIds.LAST_NAME) {
      if (event.target.value) {
        setLastName({
          value: event.target.value,
          error: validator.isLength(event.target.value, {
            min: appConfig.MAX_LENGTH.NAME.MIN,
            max: appConfig.MAX_LENGTH.NAME.MAX,
          })
            ? null
            : ErrorMessages.LAST_NAME_NOT_VALID,
        });
      } else {
        setLastName({ value: "", error: null });
      }
    } else if (inputId === InputIds.AGE) {
      if (event.target.value) {
        setAge({
          value: event.target.value,
          error:
            event.target.value >= 18 && event.target.value <= 40
              ? null
              : ErrorMessages.AGE,
        });
      } else {
        setAge({ value: "", error: null });
      }
    } else if (inputId === InputIds.CONTACT) {
      if (event.target.value) {
        const formatedNumber = mobileNumberFormat(event.target.value);
        if (formatedNumber) {
          setContact({
            value: formatedNumber,
            error: validator.isMobilePhone(formatedNumber, "en-CA")
              ? null
              : ErrorMessages.MOBILE_NOT_VALID,
          });
        }
      } else {
        setContact({ value: "", error: null });
      }
    } else if (inputId === InputIds.EMAIL) {
      if (event.target.value) {
        setEmail({
          value: event.target.value,
          error: validator.isEmail(event.target.value)
            ? null
            : ErrorMessages.EMAIL_NOT_VALID,
        });
      } else {
        setEmail({ value: "", error: null });
      }
    } else if (inputId === InputIds.COMPLETED_EDUCATION) {
      if (event.target.value) {
        setCompletedEducation({ value: event.target.value, error: null });
      } else {
        setCompletedEducation({ value: "", error: null });
      }
    } else if (inputId === InputIds.WORK_EXPERIENCE_AND_FILED) {
      if (event.target.value) {
        setWorkExperience({ value: event.target.value, error: null });
      } else {
        setWorkExperience({ value: "", error: null });
      }
    } else if (inputId === InputIds.MARITAL_STATUS) {
      if (event.target.value) {
        setMaritalStatus({ value: event.target.value, error: null });
      } else {
        setMaritalStatus({ value: "", error: null });
      }
    } else if (inputId === InputIds.SPOUSE_FIRST_NAME) {
      if (event.target.value) {
        setSpouseFirstName({
          value: event.target.value,
          error: validator.isLength(event.target.value, {
            min: appConfig.MAX_LENGTH.NAME.MIN,
            max: appConfig.MAX_LENGTH.NAME.MAX,
          })
            ? null
            : ErrorMessages.FIRST_NAME_NOT_VALID,
        });
      } else {
        setSpouseFirstName({ value: "", error: null });
      }
    } else if (inputId === InputIds.SPOUSE_LAST_NAME) {
      if (event.target.value) {
        setSpouseLastName({
          value: event.target.value,
          error: validator.isLength(event.target.value, {
            min: appConfig.MAX_LENGTH.NAME.MIN,
            max: appConfig.MAX_LENGTH.NAME.MAX,
          })
            ? null
            : ErrorMessages.FIRST_NAME_NOT_VALID,
        });
      } else {
        setSpouseLastName({ value: "", error: null });
      }
    } else if (inputId === InputIds.SPOUSE_AGE) {
      if (event.target.value) {
        setSpouseAge({
          value: event.target.value,
          error:
            event.target.value >= 18 && event.target.value <= 50
              ? null
              : ErrorMessages.SPOUSE_AGE,
        });
      } else {
        setSpouseAge({ value: "", error: null });
      }
    } else if (inputId === InputIds.SPOUSE_EDUCATION) {
      if (event.target.value) {
        setSpouseEducation({ value: event.target.value, error: null });
      } else {
        setSpouseEducation({ value: "", error: null });
      }
    } else if (inputId === InputIds.REFERENCE_NAME) {
      if (event.target.value) {
        setReferenceName({ value: event.target.value, error: null });
      } else {
        setReferenceName({ value: "", error: null });
      }
    } else if (inputId === InputIds.COMMENT) {
      if (event.target.value) {
        setComment({ value: event.target.value, error: null });
      } else {
        setComment({ value: "", error: null });
      }
    }
  };
  let disabledButton =
    firstName.value &&
    !firstName.error &&
    lastName.value &&
    !lastName.error &&
    age.value &&
    !age.error &&
    contact.value &&
    !contact.error &&
    email.value &&
    !email.error &&
    completedEducation.value &&
    !completedEducation.error &&
    workExperience.value &&
    !workExperience.error &&
    maritalStatus.value &&
    !maritalStatus.error;

  if (disabledButton && maritalStatus.value === appConfig.MARITAL_STATUS[1]) {
    disabledButton =
      disabledButton &&
      spouseFirstName.value &&
      !spouseFirstName.error &&
      spouseLastName.value &&
      !spouseLastName.error &&
      spouseEducation.value &&
      !spouseEducation.error &&
      spouseAge.value &&
      !spouseAge.error;
  }
  return (
    <div>
      <Header page={Messages.CONTACT_US} />
      <CommonBanner
        img={appConfig.COMMON_BANNER[1].IMG}
        description={appConfig.COMMON_BANNER[1].DESCRIPTION}
      />
      <div className="new-contact-wrapper" id="new-contact-wrapper">
        <div className="contect-content container-padding">
          <div className="row">
            <div className="col-sm-12">
              <div className="left-section">
                <div className="know-us-better-section center">
                  <div className="contact-details-block address-block">
                    <div className="address-text">
                      <span className="left-section-text">
                        Toronto, ON
                        <div>
                          <span className="left-section-text">
                            M5E 1W7 Canada.
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="contact-details-block mail-block">
                    <div className="address-text">
                      <a
                        href="mailto:info@nhss.ca"
                        className="left-section-text"
                      >
                        info@nhss.ca{" "}
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="contact-street-map mt-20">
                  
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.236107018591!2d-79.37643704868627!3d43.64325581098884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb2960447d9b%3A0xe103f4a593e21fbc!2sToronto%2C%20ON%20M5E%201W7%2C%20Canada!5e0!3m2!1sen!2sin!4v1655462199477!5m2!1sen!2sin"
                    width="100%"
                    height="400px"
                    frameBorder="0"
                    aria-hidden={false}
                    tabIndex="0"
                    allowFullScreen=""
                    title="canada-map"
                  ></iframe>
                </div> */}
              </div>
              <div className="right-section mt-40">
                <div className="contact-section">
                  <div className="contact-box">
                    <div className="text-center contact-text">
                      <span className="contact-text">CONTACT US</span>
                    </div>
                  </div>
                  <div className="form-details">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.FIRST_NAME}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.FIRST_NAME)}
                            value={firstName.value}
                          />
                          {firstName && firstName.error && (
                            <div className="badge badge-danger">
                              {firstName.error}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.LAST_NAME}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.LAST_NAME)}
                            value={lastName.value}
                          />
                          {lastName && lastName.error && (
                            <div className="badge badge-danger">
                              {lastName.error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.AGE}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.AGE)}
                            value={age.value}
                          />
                          {age && age.error && (
                            <div className="badge badge-danger">
                              {age.error}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.PHONE_NUMBER}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.CONTACT)}
                            value={contact.value}
                          />
                          {contact && contact.error && (
                            <div className="badge badge-danger">
                              {contact.error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.EMAIL}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.EMAIL)}
                            value={email.value}
                          />
                          {email && email.error && (
                            <div className="badge badge-danger">
                              {email.error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className={"input-block dropdown-block"}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            SelectProps={{
                              MenuProps: { disableScrollLock: true },
                            }}
                            label={Labels.COMPLETED_EDUCATION}
                            value={completedEducation.value}
                            onChange={onInputChange(
                              InputIds.COMPLETED_EDUCATION
                            )}
                          >
                            {appConfig.COMPLETED_EDUCATION.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.WORK_EXPERIENCE_AND_FILED}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(
                              InputIds.WORK_EXPERIENCE_AND_FILED
                            )}
                            value={workExperience.value}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="input-block dropdown-block">
                          <TextField
                            id="outlined-select-currency"
                            select
                            SelectProps={{
                              MenuProps: { disableScrollLock: true },
                            }}
                            label={Labels.MARITAL_STATUS}
                            value={maritalStatus.value}
                            onChange={onInputChange(InputIds.MARITAL_STATUS)}
                          >
                            {appConfig.MARITAL_STATUS.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>

                    {maritalStatus.value === appConfig.MARITAL_STATUS[1] ? (
                      <div>
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="input-block">
                              <TextField
                                fullWidth
                                label={Labels.SPOUSE_FIRST_NAME}
                                // id='fullWidth'
                                variant="outlined"
                                onChange={onInputChange(
                                  InputIds.SPOUSE_FIRST_NAME
                                )}
                                value={spouseFirstName.value}
                              />
                              {spouseFirstName && spouseFirstName.error && (
                                <div className="badge badge-danger">
                                  {spouseFirstName.error}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input-block">
                              <TextField
                                fullWidth
                                label={Labels.SPOUSE_LAST_NAME}
                                // id='fullWidth'
                                variant="outlined"
                                onChange={onInputChange(
                                  InputIds.SPOUSE_LAST_NAME
                                )}
                                value={spouseLastName.value}
                              />
                              {spouseLastName && spouseLastName.error && (
                                <div className="badge badge-danger">
                                  {spouseLastName.error}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="input-block">
                              <TextField
                                fullWidth
                                label={Labels.SPOUSE_AGE}
                                // id='fullWidth'
                                variant="outlined"
                                onChange={onInputChange(InputIds.SPOUSE_AGE)}
                                value={spouseAge.value}
                              />
                              {spouseAge && spouseAge.error && (
                                <div className="badge badge-danger">
                                  {spouseAge.error}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input-block dropdown-block">
                              <TextField
                                id="outlined-select-currency"
                                select
                                SelectProps={{
                                  MenuProps: { disableScrollLock: true },
                                }}
                                label={Labels.SPOUSE_EDUCATION}
                                value={spouseEducation.value}
                                onChange={onInputChange(
                                  InputIds.SPOUSE_EDUCATION
                                )}
                              >
                                {appConfig.COMPLETED_EDUCATION.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-12">
                        <div className="input-block">
                          <TextField
                            fullWidth
                            label={Labels.REFERENCE_NAME}
                            // id='fullWidth'
                            variant="outlined"
                            onChange={onInputChange(InputIds.REFERENCE_NAME)}
                            value={referenceName.value}
                          />
                          {referenceName && referenceName.error && (
                            <div className="badge badge-danger">
                              {referenceName.error}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          fullWidth
                          label={Labels.COMMENT}
                          // id='fullWidth'
                          multiline
                          rows={4}
                          variant="outlined"
                          onChange={onInputChange(InputIds.COMMENT)}
                          value={comment.value}
                        />
                        {comment && comment.error && (
                          <div className="badge badge-danger">
                            {comment.error}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <Button
                      type="submit"
                      className={"btn btn-primary submit-now"}
                      text={Messages.SUBMIT_NOW}
                      disabled={!disabledButton}
                      onClick={onContactUsClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ContactUsPopup open={openPopup} onCloseClick={onCloseClick} />
    </div>
  );
}
