// import headerLogoImage from '../component/images/common/header-logo1.svg'

export const ImageAssets = {
  common: {
    headerLogo: require("../component/images/common/header-logo1.png"),
    // headerLogo: headerLogoImage,
    menuIcon: require("../component/images/common/menu-icon1.png"),
    facebook: require("../component/images/common/footer-fb-icon.png"),
    linkdin: require("../component/images/common/footer-linkdin-icon.png"),
    twitter: require("../component/images/common/footer-twitter-icon.png"),
    rightArrow: require("../component/images/common/right-arrow.png"),
    dotedSymbole: require("../component/images/common/doted-symbole.png"),
    bannerImageOne: require("../component/images/common/banner-bg-image.png"),
    bannerImageTwo: require("../component/images/common/home-banner-second-img.png"),
    bannerImageThree: require("../component/images/common/home-banner-third-img.png"),
    aboutBannerImage: require("../component/images/common/about-banner-img.png"),
    serviceBannerImage: require("../component/images/common/service-banner-img.png"),
    contactBannerImage: require("../component/images/common/contact-banner-img.png"),
    blogBannerImage: require("../component/images/common/blog-banner-img.png"),
    popupImage: require("../component/images/common/popup-img.png"),
    closePopupIcon: require("../component/images/common/close-popup-icon.png"),
    popupCominSoonImg: require("../component/images/common/coming-soon.png"),
  },
  home: {
    studyAbroad: require("../component/images/home/study-abroad-img.png"),
    profileAssessment: require("../component/images/home/profile-assessment-img.png"),
    immigration: require("../component/images/home/immigration-img.png"),
    visaService: require("../component/images/home/visa-services-img.png"),
    consultation: require("../component/images/home/consultation-img.png"),
    studentYourSuccess: require("../component/images/home/our-success-student-icon.png"),
    successfullVisaYourSuccess: require("../component/images/home/successful-visa-icon.png"),
    immigrationsYourSuccess: require("../component/images/home/immigration-icon.png"),
    universitiesYourSuccess: require("../component/images/home/universities-icon.png"),
    lookingForVisaSection: require("../component/images/home/looking-for-visa-section-bg-image.png"),
    studentImage: require("../component/images/home/student-image.png"),
  },
  contact: {
    addressIcon: require("../component/images/contact/location-icon.png"),
    callIcon: require("../component/images/contact/call-icon.png"),
    mailIcon: require("../component/images/contact/mail-icon.png"),
    clockIcon: require("../component/images/contact/clock-icon.png"),
    consultation: require("../component/images/home/consultation-img.png"),
  },
  about: {
    introLeftImaege: require("../component/images/about/introduction-left-image.png"),
    introRightImaege: require("../component/images/about/about-img1.png"),
    ourStoryImage: require("../component/images/about/our-story-our-vision.png"),
    whyChooseImage: require("../component/images/about/why-choose-us-image.png"),
    reginaUni: require("../component/images/about/regina-university.png"),
    uniOfToronto: require("../component/images/about/university-of-toronto.png"),
    lowrenceCollege: require("../component/images/about/mcgill-uni.png"),
    yorkUni: require("../component/images/about/york-university.png"),
  },
  service: {
    nhssContent: require("../component/images/service/nhss-content-img.png"),
    letsConnectImg: require("../component/images/service/lets-connect-right-img.png"),
    letUsHelp: require("../component/images/service/let-us-help-img.png"),
  },
  blog: {
    immigrationLaws: require("../component/images/blog/immigration-laws.png"),
    whyStudyCanada: require("../component/images/blog/why-study-in-canada.png"),
    currentAffairsCanada: require("../component/images/blog/current-affairs-canada.png"),
    howToAchieveAim: require("../component/images/blog/how-to-achieve-your-aim.png"),
    profileAssessment: require("../component/images/blog/profile-assessment.png"),
    consultAndCounselling: require("../component/images/blog/consult-and-counselling.png"),
  },
  adminPanel: {
    appView: require("../component/images/admin-panel/view-app-icon.png"),
    deleteIcon: require("../component/images/admin-panel/delete-small-icon.png"),
    editIcon: require("../component/images/admin-panel/edit.png"),
  },
};
export default ImageAssets;
