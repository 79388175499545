import React from 'react'

function Button(props) {
    return (
      <div className={props.parentClassName}>
        <button
          type={props.type}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.text}
          
        </button>
      </div>
    )
}

Button.propTypes = {}

export default Button