import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";

const appReducer = combineReducers({
    loading : loadingReducer
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}
export default rootReducer