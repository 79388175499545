import React, { useRef, useState } from "react";
import ImageAssets from "../../utils/ImageAssests";
import Button from "./Button";
import { Messages } from "../../constants/StaticTexts";
import { Link } from "react-router-dom";
import { UIRoutes } from "../../constants/FrontendRoutes";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function Header(props) {
  const ref = useRef();
  const buttonRef = useRef();
  const { page } = props;
  const [open, setOpen] = useState(false);
  const clickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpen(!open);
    } else return;
  };
  window.addEventListener("mousedown", clickOutside);
  const openHeaderMenu = () => {
    setOpen(!open);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  return (
    <div>
      <div className="header-main-panel">
        <div className="header-content container-padding d-flex justify-content-between align-items-center">
          <div className="header-logo">
            <Link to={UIRoutes.HOME}>
              <img src={ImageAssets.common.headerLogo} alt="header-logo" />
            </Link>
          </div>
          <div className="header-menu">
            <div className="d-flex flex-wrap">
              <div className="desktop-button-panel d-flex flex-wrap">
                <div className="login-button pr-20">
                  <Button
                    type="submit"
                    className={"btn btn-secondary"}
                    text={Messages.LOGIN}
                    onClick={handleOpen}
                  ></Button>
                </div>
                <div className="get-appoinment-button">
                  <Link to={UIRoutes.CONTACTUS}>
                    <Button
                      type="submit"
                      className={"btn btn-secondary btn-medium"}
                      text={Messages.CONTACT_US}
                    ></Button>
                  </Link>
                </div>
              </div>
              <div className="pl-30 menu-section" ref={buttonRef}>
                <div className="menu-block" onClick={openHeaderMenu}></div>
              </div>
            </div>
          </div>
        </div>
        {open ? (
          <div className="header-menu-items-section" ref={ref}>
            <div className="header-menu-upper-img"></div>
            <div
              className="close-icon position-absolute"
              onClick={openHeaderMenu}
            >
              {" "}
            </div>
            <div className="header-items-menu d-flex justify-content-center flex-column text-center">
              <Link
                to={UIRoutes.HOME}
                className={
                  "header-item position-relative " +
                  (page === Messages.HOME ? "active-menu" : "")
                }
              >
                {" "}
                <span>{Messages.HOME}</span>
              </Link>
              <Link
                to={UIRoutes.ABOUTUS}
                className={
                  "header-item " +
                  (page === Messages.ABOUT ? "active-menu" : "")
                }
              >
                {" "}
                <span>{Messages.ABOUT}</span>
              </Link>
              {/* <Link to={UIRoutes.SERVICES} className={'header-item ' + (page === Messages.SERVICES ? 'active-menu' : '')}> <span>{Messages.SERVICES}</span></Link> */}
              <Link
                to={UIRoutes.CONTACTUS}
                className={
                  "header-item " +
                  (page === Messages.CONTACT_US ? "active-menu" : "")
                }
              >
                {" "}
                <span>{Messages.CONTACT_US}</span>
              </Link>
              <Link
                to={UIRoutes.BLOG}
                className={
                  "header-item " + (page === Messages.BLOG ? "active-menu" : "")
                }
              >
                {" "}
                <span>{Messages.BLOG}</span>
              </Link>
              <Link to={UIRoutes.LOGIN}>
                <Button
                  type="submit"
                  className={"btn btn-secondary mobile-btn mt-20"}
                  text={Messages.LOGIN}
                ></Button>
              </Link>
              <Link to={UIRoutes.CONTACTUS}>
                <Button
                  type="submit"
                  className={"btn btn-secondary btn-medium mt-30 mobile-btn"}
                  text={Messages.CONTACT_US}
                ></Button>
              </Link>
            </div>
            <div className="header-menu-lower-img"> </div>
          </div>
        ) : null}
      </div>

      <div>
        <Dialog aria-labelledby="customized-dialog-title" open={openModal}>
          <DialogTitle id="customized-dialog-title">
            <div className="d-flex justify-content-end cancel-btn">
              <img
                src={ImageAssets.common.closePopupIcon}
                alt="close-icon"
                className="img-responsive"
                onClick={handleClose}
              ></img>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <div className="popup-icon-block">
              <div className="d-flex justify-content-center right-image-block">
                <img
                  src={ImageAssets.common.popupCominSoonImg}
                  alt="popup"
                  className="img-responsive"
                ></img>
              </div>
            </div>
            <div className="title-panel mt-30 text-center">
              <div>
                <span className="title-panel-text font-600 font-16 ">
                  Online registration and login coming soon.
                </span>
              </div>
              <div>
                <span className="title-panel-text font-600 font-16 ">
                  Please use the contact us form to reach us.
                </span>
              </div>
            </div>
            <div className="button-panel mt-20 text-center center">
              <Link
                to={UIRoutes.CONTACTUS}
                type="submit"
                className={"btn btn-primary ok"}
                onClick={handleClose}
              >
                {Messages.CONTACT_US}
              </Link>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
