import React from 'react'
import ImageAssets from '../../utils/ImageAssests'
import Button from '../common/Button'
import { Messages } from '../../constants/StaticTexts'
import Header from '../common/Header'
import CommonBanner from '../common/CommonBanner'
import { appConfig } from '../../constants/AppConfig'
import Footer from '../common/Footer'
import { TextField } from '@mui/material'
export default function Blog() {
    return (
        <div>
            <Header page={Messages.BLOG}/>
            <CommonBanner img={appConfig.COMMON_BANNER[2].IMG} description={appConfig.COMMON_BANNER[2].DESCRIPTION} />
            <div className='blog-wrapper' id='blog-wrapper'>
                <div className='blog-content container-padding'>
                    <div className='row respo-gutters'>
                        <div className='col-xl-4 col-lg-5 col-md-6 col-12'>
                            <div className='left-content'>
                                <div className='search-blog'>
                                    <div className='heading'><span className='heading-text'>Search our<span className='red-text'> Blogs</span></span></div>
                                    <div className='search-area'>
                                        {/* <TextField
                                            fullWidth
                                            label='Search.....'
                                            id='fullWidth'
                                            variant="standard"
                                        /> */}
                                        <TextField
                                            fullWidth
                                            id="fullWidth"
                                            label=""
                                            placeholder='Search...'
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                                <div className='recent-posts'>
                                    <div className='heading'><span className='heading-text'>Recent<span className='heading-text red-text pl-5'>Posts</span></span></div>
                                    <div className='recent-post-list'>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Tips for finding the finest Universities of your interest</span></div>
                                        </div>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Tips for finding the finest Universities of your interest</span></div>
                                        </div>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Visa Renewal: Make your visa renewal process easy by choosing us</span></div>
                                        </div>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Visa Renewal: Make your visa renewal process easy by choosing us</span></div>
                                        </div>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Top 10 reasons to apply for college scholarships and the benefits that come along</span></div>
                                        </div>
                                        <div className='posts-heading-text'>
                                            <div className='posts-text'><span className='text-description'>Top 10 reasons to apply for college scholarships</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='categories mb-30'>
                                    <div className='heading'><span className='heading-text'>Categories</span></div>
                                    <div className='categories-tag-list'>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Visa</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>University</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Scholarship Application</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Business</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Aim</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Immigration Laws</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Study</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Assessment</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Profile</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Consultation</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Other Services</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>Blogs</a>
                                        </div>
                                        <div className='categories-tag-item'>
                                            <a href='/'>News</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-8 col-lg-7 col-md-6 col-12'>
                            <div className='right-content mb-20'>
                                <div className='row'>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.immigrationLaws} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color'>Immigration Laws</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>Apr 30, 2022</span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-colorr blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.whyStudyCanada} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color blog-body-text'>Why study in Canada?</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>May 20, 2021</span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-color blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.currentAffairsCanada} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color blog-body-text'>Current Affairs Canada</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>Jun 01, 2022</span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-colorr blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.currentAffairsCanada} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color blog-body-text'>How to achieve your aim?</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>Dec 24, 2021 </span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-color blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.profileAssessment} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color blog-body-text'>Profile Assessment</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>Jan 06, 2022</span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-colorr blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-6'>
                                        <div className='blog-card'>
                                            <div className=''>
                                                <div className='blog-img'><img src={ImageAssets.blog.consultAndCounselling} alt='immigration-laws' /></div>
                                            </div>
                                            <div className='blog-body'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div><span className='text-primary-color blog-body-text'>Consult and Counselling</span></div>
                                                    <div className='ml-15'><span className='pipe gray-color'>|</span></div>
                                                    <div className='ml-15'><span className='gray-color blog-body-text'>Feb 28, 2022</span></div>
                                                </div>
                                                <div className='mt-20'>
                                                    <div className='blog-heading'><span className='gray-color blog-title'>In publishing and graphic design, Lorem ipsum is a placeholder text. </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blog-footer mb-20'>
                                                <Button
                                                    type='submit'
                                                    className={'btn btn-primary submit-now'}
                                                    text={Messages.READ_MORE} >
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}
