export const ErrorMessages = {
    COMMON_ERROR_MESSAGE: 'Something Went Wrong. Please Try After Sometime',
    FIRST_NAME_NOT_VALID: 'First name must be between 2-20 characters',
    LAST_NAME_NOT_VALID: 'Last name must be between 2-20 characters',
    AGE: 'Age must be between 18 to 40',
    SPOUSE_AGE: 'Age must be between 18 to 50',
    EMAIL_NOT_VALID: 'Email address is not valid',
    MOBILE_NOT_VALID: 'Mobile number is not valid',
    MESSAGE_NOT_VALID: 'Message must be between 10-100 characters',
    INSPECTION_DATE: 'Inspection date is not valid'
}