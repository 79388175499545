import axios from "axios";
import { toast } from "react-toastify";
import { APIRoutes } from "../constants/BackendRoutes";
import { getErrorMessage } from "../utils/GetErrorMessage";
import setLoading from "../utils/LoadingManager";

export const contactUsAction = (requestObject, onSuccess) => {
  setLoading(true);
  axios
    .post(APIRoutes.COMMON.CONTACT_US, requestObject)
    .then((res) => {
      onSuccess(res);
      setLoading(false);
    })
    .catch((err) => {
      toast.error(getErrorMessage(err));
      // onSuccess()
      setLoading(false);
    });
};
