import React, { useState } from 'react'
import Button from '../common/Button'
import { Messages, InputIds, Labels } from '../../constants/StaticTexts'
import { TextField } from '@mui/material'
import ImageAssets from '../../utils/ImageAssests'
import { mobileNumberFormat } from '../../utils/Utils'
import { appConfig } from '../../constants/AppConfig'
import Header from '../common/Header'
import Footer from '../common/Footer'
import CommonBanner from '../common/CommonBanner'
import { ErrorMessages } from '../../constants/ErrorMessages'
import validator from 'validator'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Service() {
    const [name, setName] = useState({ value: '', error: null })
    const [contact, setContact] = useState({ value: '', error: null })
    const [inspectionDate, setInspectionDate] = useState({ value: '', error: null })

    const onInputChange = (inputId) => (event) => {
        event.preventDefault()
        if (inputId === InputIds.NAME) {
            if (event.target.value) {
                setName({ value: event.target.value, error: validator.isLength(event.target.value, { min: appConfig.MAX_LENGTH.NAME.MIN, max: appConfig.MAX_LENGTH.NAME.MAX }) ? null : ErrorMessages.NAME_NOT_VALID })
            } else {
                setName({ value: '', error: null })
            }
        } else if (inputId === InputIds.CONTACT) {
            if (event.target.value) {
                const formatedNumber = mobileNumberFormat(event.target.value)
                if (formatedNumber) {
                    setContact({ value: formatedNumber, error: validator.isMobilePhone(formatedNumber, 'en-CA') ? null : ErrorMessages.MOBILE_NOT_VALID })
                }
            } else {
                setContact({ value: '', error: null })
            }
        }
    }

    const onDateChange = (inputId) => (value) => {
        if (inputId === InputIds.INSPECTIONDATE) {
            if (value) {
                setInspectionDate({ value: value, error: validator.isDate(value) ? null : ErrorMessages.INSPECTION_DATE })
            } else {
                setInspectionDate({ value: '', error: null })
            }
        }
    }
    return (
        <div>
            <Header page={Messages.SERVICES} />
            <CommonBanner img={appConfig.COMMON_BANNER[3].IMG} description={appConfig.COMMON_BANNER[3].DESCRIPTION} />
            <div className='service-main-panel' id='service-main-panel'>
                <div className='service-padding'>
                    <div className='service-main-content'>
                        <div className='our-service-section'>
                            <div className='heading-section'>
                                <div className='heading-class'> <h2 className='gray-color'> Our  <span className='text-primary-color'> Service </span> </h2> </div>
                                <div className='heading-text'><span>New Horizon Student Services</span></div>
                            </div>
                            <div className='service-cards-section-bg-image'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row justify-content-between'>
                                            {/* <div className='service-cards-section d-flex justify-content-between w-100 flex-wrap flex-content'> */}
                                            <div className='col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-4'>
                                                <div className='service-cards-section'>
                                                    <div className='service-card d-flex justify-content-between align-items-center'>
                                                        <div className='left-text-content order-left-resp'>
                                                            <span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                                                                without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                                                The passage is attributed to an unknown typesetter in the 15th century who is thought t
                                                                o have scrambled parts.</span>
                                                        </div>
                                                        <div className='right-image-content'>
                                                            <div className='image-wrapper'> <img src={ImageAssets.home.immigration} alt='immigration' className='img-responsive' /> </div>
                                                            <div className='text-center mt-20 title-text'> <span className='font-700'> IMMIGRATION </span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-6 col-lg-6  col-xl-5 col-xxl-4'>
                                                <div className='service-cards-section'>
                                                    <div className='service-card d-flex justify-content-between align-items-center'>
                                                        <div className='left-text-content  order-left-resp'>
                                                            <span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                                                                without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                                                The passage is attributed to an unknown typesetter in the 15th century who is thought t
                                                                o have scrambled parts.</span>
                                                        </div>
                                                        <div className='right-image-content'>
                                                            <div className='image-wrapper'> <img src={ImageAssets.home.consultation} alt='immigration' className='img-responsive' /> </div>
                                                            <div className='text-center mt-20 title-text'> <span className='font-700'> CONSULTATION</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row justify-content-around'>
                                            {/* <div className='service-cards-section d-flex justify-content-around w-100 flex-wrap'> */}
                                            <div className='col-sm-6 col-md-6 col-lg-6  col-xl-5 col-xxl-4'>
                                                <div className='service-cards-section'>
                                                    <div className='service-card d-flex justify-content-between align-items-center service-resp-padding'>
                                                        <div className='left-text-content  order-left-resp'>
                                                            <span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                                                                without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                                                The passage is attributed to an unknown typesetter in the 15th century who is thought t
                                                                o have scrambled parts.</span>
                                                        </div>
                                                        <div className='right-image-content'>
                                                            <div className='image-wrapper'> <img src={ImageAssets.home.studyAbroad} alt='immigration' className='img-responsive' /> </div>
                                                            <div className='text-center mt-20 title-text'> <span className='font-700'>STUDY ABROAD </span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-md-6 col-lg-6  col-xl-5 col-xxl-4'>
                                                <div className='service-cards-section'>
                                                    <div className='service-card d-flex justify-content-between align-items-center'>
                                                        <div className='left-text-content  order-left-resp'>
                                                            <span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                                                                without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                                                The passage is attributed to an unknown typesetter in the 15th century who is thought t
                                                                o have scrambled parts.</span>
                                                        </div>
                                                        <div className='right-image-content'>
                                                            <div className='image-wrapper'> <img src={ImageAssets.home.profileAssessment} alt='immigration' className='img-responsive' /> </div>
                                                            <div className='text-center mt-20 title-text'> <span className='font-700'> PROFILE ASSESSMENT </span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='service-cards-section d-flex justify-content-center flex-wrap w-100'>  */}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row  justify-content-center'>
                                            <div className='col-sm-6 col-md-6 col-lg-6  col-xl-5 col-xxl-4'>
                                                <div className='service-cards-section'>
                                                    <div className='service-card d-flex justify-content-between align-items-center'>
                                                        <div className='left-text-content  order-left-resp'>
                                                            <span> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface
                                                                without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                                                                The passage is attributed to an unknown typesetter in the 15th century who is thought t
                                                                o have scrambled parts.</span>
                                                        </div>
                                                        <div className='right-image-content'>
                                                            <div className='image-wrapper'> <img src={ImageAssets.home.visaService} alt='immigration' className='img-responsive' /> </div>
                                                            <div className='text-center mt-20 title-text'> <span className='font-700'> VISA SERVICES </span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='nhss-services-panel'>
                            <div className='nhss-services-main-content container-padding'>
                                <div className='nhss-service-content'>
                                    <div className='row no-gutters'>
                                        <div className='col-md-12 col-lg-8'>
                                            <div className='service-left-content'>
                                                <div className='row'>
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className='content-card'>
                                                            <div className='content-image pb-20'> <img src={ImageAssets.service.nhssContent} alt='nhss content' className='img-responsive' /> </div>
                                                            <div className='content-name pb-20'> <span className='font-20 black-color font-700'> Business Setup Services</span></div>
                                                            <div> <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a
                                                                document or a typeface without relying on meaningful content. </p></div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className='content-card'>
                                                            <div className='content-image pb-20'> <img src={ImageAssets.service.nhssContent} alt='nhss content' className='img-responsive' /> </div>
                                                            <div className='content-name pb-20'> <span className='font-20 black-color font-700'> Business Setup Services</span></div>
                                                            <div> <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a
                                                                document or a typeface without relying on meaningful content. </p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row pt-30'>
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className='content-card'>
                                                            <div className='content-image pb-20'> <img src={ImageAssets.service.nhssContent} alt='nhss content' className='img-responsive' /> </div>
                                                            <div className='content-name pb-20'> <span className='font-20 black-color font-700'> Business Setup Services</span></div>
                                                            <div> <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a
                                                                document or a typeface without relying on meaningful content. </p></div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className='comtent-card'>
                                                            <div className='content-image pb-20'> <img src={ImageAssets.service.nhssContent} alt='nhss content' className='img-responsive' /> </div>
                                                            <div className='content-name pb-20'> <span className='font-20 black-color font-700'> Business Setup Services</span></div>
                                                            <div> <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a
                                                                document or a typeface without relying on meaningful content. </p> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 col-lg-4'>
                                            <div className='service-right-content h-100'>
                                                <div className='d-flex justify-content-around flex-column h-100 align-items-end flex-content-wrapper'>
                                                    <div className='service-heading-section text-right'>
                                                        <div> <h2 className='text-white'> NHSS Services</h2> </div>
                                                        <div> <p className='text-white'> Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> </div>
                                                    </div>
                                                    <div className='view-all-btn'>
                                                        <Button
                                                            type='submit'
                                                            className={'btn btn-white'}
                                                            text={Messages.VIEW_ALL} >
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='let-us-help-section'>
                            <div className='let-us-help-content container-padding'>
                                <div className='row no-gutters'>
                                    <div className='col-sm-12 col-lg-6 col-xl-6'>
                                        <div className='left-section'>
                                            <div className='heading-class mb-10'>
                                                <h2 className='gray-color'>Let Us Help You </h2>
                                                <h2 className='text-primary-color'> Scheduling An Appointment </h2>
                                            </div>
                                            <div className='heading-description'>
                                                <div><span>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without
                                                    relying on meaningful content. </span> </div>
                                            </div>
                                            <div className='image-wrapper mt-20'>
                                                <img src={ImageAssets.service.letUsHelp} alt='nhss content' className='img-responsive' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-lg-6 col-xl-6'>
                                        <div className='right-section  position-relative'>
                                            <div className='get-online-section position-relative'>
                                                <div className='get-online-box position-absolute'>
                                                    <div className='text-center get-online-text'><span className='text-white font-700'>GET ONLINE CONSULTATION</span></div>
                                                </div>
                                                <div className='form-details'>
                                                    <div className='name-input mb-20'>
                                                        <TextField
                                                            fullWidth
                                                            label={Labels.FULL_NAME}
                                                            // id='fullWidth' 
                                                            onChange={onInputChange(InputIds.NAME)}
                                                            value={name.value}
                                                        />
                                                        {name && name.error && <div className='badge badge-danger'>{name.error}</div>}
                                                    </div>
                                                    <div className='phone-input mb-20'>
                                                        <TextField
                                                            fullWidth
                                                            label={Labels.PHONE_NUMBER}
                                                            // id='fullWidth'
                                                            onChange={onInputChange(InputIds.CONTACT)}
                                                            value={contact.value}
                                                        />
                                                        {contact && contact.error && <div className='badge badge-danger'>{contact.error}</div>}
                                                    </div>
                                                    <div className='inspection-input mb-20'>
                                                        {/* <TextField
                                                            fullWidth
                                                            label='Inspection Date'
                                                        // id='fullWidth' 
                                                        /> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label={Labels.INSPECTION_DATE}
                                                                value={inspectionDate.value}
                                                                openTo="year"
                                                                views={["year", "month", "day"]}
                                                                onChange={onDateChange(InputIds.INSPECTIONDATE)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='location-input mb-20'>
                                                        <TextField
                                                            fullWidth
                                                            label={Labels.LOCATION}
                                                        // id='fullWidth' 
                                                        />
                                                    </div>
                                                    <div className='button-wrapper'>
                                                        <Button
                                                            type='submit'
                                                            className={'btn btn-primary book-consult'}
                                                            text={Messages.BOOK_FOR_CONSULT} >
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lets-connect-section sub-section-padding'>
                    <div className='lets-connect-content position-relative container-padding'>
                        <div className='row no-gutters'>
                            <div className='col-md-7 col-lg-8'>
                                <div className='left-text-section'>
                                    <h2 className='text-white'> Unlock the worries of your immigration </h2>
                                    <h2 className='text-white'>Visa & begin your search for various universities </h2>
                                    <p className='text-white pb-10'> Get in Touch with our company.</p>
                                    <div className='button-wrapper'>
                                        <Button
                                            type='submit'
                                            className={'btn btn-white lets-connect-btn'}
                                            text={Messages.LETS_CONNECT} >
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 col-lg-4'>
                                <div className='right-image-section position-absolute'>
                                    <img src={ImageAssets.service.letsConnectImg} alt='' className='img-responsive' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

