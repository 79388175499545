/* eslint-disable prefer-destructuring */
import React from "react";
import { FormProvider, Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
// import Dropdown from "../ui/Dropdown/Dropdown"
// import MaterialInput from "./MaterialInput"

const Form = (props) => {
  const { children, reactFormContext, onSubmit } = props;

  const handleSubmit = reactFormContext.handleSubmit;

  return (
    <FormProvider {...reactFormContext}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

Form.Input = function Input(props) {
  const { name, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={(field) => (
        <TextField
          fullWidth
          {...field}
          label={props.label}
          error={!!errors[name]}
          helperText={errors[name]?.message || ""}
          {...rest}
        />
      )}
    />
  );
};

// Form.Dropdown = function DropDown(props) {
//   const { name, ...rest } = props
//   const {
//     control,
//     formState: { errors },
//     setValue,
//     getValues
//   } = useFormContext()

//   const handleUncheckedAllSelectedOptions = () => {
//     setValue(name, [])
//   }

//   return (
//     <Controller
//       control={control}
//       name={name}
//       render={({ field: { ref, ...restField } }) => {
//         return (
//           <div>
//             <Dropdown
//               inputRef={ref}
//               error={!!errors[name]}
//               uncheckedAllSelectedOptions={
//                 props.withSearch ? handleUncheckedAllSelectedOptions : undefined
//               }
//               {...rest}
//               {...restField}
//             />
//             <FormHelperText error={!!errors}>
//               {errors[name]?.message}
//             </FormHelperText>
//           </div>
//         )
//       }}
//     />
//   )
// }

export default Form;
