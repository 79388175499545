export const UIRoutes = {
  HOME: "/",
  ABOUTUS: "/about-us",
  SERVICES: "/services",
  CONTACTUS: "/contact-us",
  BLOG: "/blog",
  LOGIN: "/login",
  SIGNUP: "/signup",
  // STEPPERFORM: "/StepperForm"
};
