import React from 'react'
import ImageAssets from '../../utils/ImageAssests'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { appConfig } from '../../constants/AppConfig'
import CommonBanner from '../common/CommonBanner'
import { Messages } from '../../constants/StaticTexts'


export default function About() {
    return (
        <div>
            <Header page={Messages.ABOUT} />
            <CommonBanner img={appConfig.COMMON_BANNER[0].IMG} description={appConfig.COMMON_BANNER[0].DESCRIPTION} />
            <div className='about-main-panel  section-padding' id='about-main-panel'>
                {/* <div className='about-introduction-section'>
                    <div className='intro-description'>
                        <div className='row no-gutters'>
                            <div className='col-md-12 col-lg-4'>
                                <div className='left-intro-section'>
                                    <div className='left-image-wrapper left-wrap'> <img src={ImageAssets.about.introLeftImaege} alt="intro left" className='image-responsive' /> </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4'>
                                <div className='middle-section'>
                                    <div className='heading-class mb-20'> <h2 className='gray-color'> Who <span className='text-primary-color'> are we ?</span> </h2> </div>
                                    <div className='new-horizon-text mb-50'>
                                        <span className='text-primary-color font-600'> New Horizon Student Services</span>
                                    </div>
                                    <div className='intro-description-text mb-30'>
                                        <span> Lorem ipsum, or lipsum as it is sometimes known,
                                            is dummy text used in laying out print, graphic or web designs.
                                            The passage is attributed to an unknown typesetter in the 15th century
                                            who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use
                                            in a type specimen book. It usually begins with:
                                        </span>
                                    </div>
                                    <div className='intro-description-text mb-30'>
                                        <span> “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua.”
                                            The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.)
                                            that doesn't distract from the layout. A practice not without controversy, laying out pages with
                                            meaningless filler text can be very useful when the focus is meant to be on design, not content.

                                        </span>
                                    </div>
                                    <div className='intro-description-text'>
                                        <span>The passage experienced a surge in popularity during the 1960s when Letraset used it on their
                                            dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software.
                                            Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get
                                            your own, or read on for the authoritative history of lorem ipsum. Lorem ipsum, or lipsum as it is
                                            sometimes known, is dummy text used in laying out print, graphic or web designs.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4'>
                                <div className='right-intro-section'>
                                    <div className='right-image-wrapper right-wrap'> <img src={ImageAssets.about.introRightImaege} alt="intro right" className='image-responsive' /> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='our-story-our-vision-section'>
                    <div className='our-story-description  sub-section-padding'>
                        <div className='row no-gutters'>
                            <div className='col-12 col-lg-6'>
                                <div className='left-content'>
                                    <div className='heading-class mb-30'> <h2 className='gray-color'> Our Story, <span className='text-primary-color'> Our Vision!</span> </h2> </div>
                                    <div className='left-description '>
                                        <div className='left-description-text mb-30'>
                                            <span> New Horizons Student Services is an International Student Recruitment agency to provide honest expert advice to all potential aspirants seeking higher education in Canada.  We have been serving the international student community through our various volunteer activities in the community for over a decade. We have been providing assistance with housing support, job search, supporting international student seminars, and more.
                                            </span>
                                        </div>
                                        <div className='left-description-text mb-30'>
                                            <span>We consider it our professional and personal responsibility to evaluate and assess each and every case very prudently. We owe it to the applicant and their family to provide them with the best possible advice when it comes to their higher education journey. Meanwhile, we also understand and respect the recruitment practices and needs of the Canadian institution recruiting international students for their prestigious programs.
                                            </span>
                                        </div>
                                        {/* <div className='left-description-text mb-30'>
                                            <span>The passage experienced a surge in popularity during the 1960s when Letraset used it on their
                                                dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software.
                                                Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get
                                                your own, or read on for the authoritative history of lorem ipsum. Lorem ipsum, or lipsum as it is
                                                sometimes known, is dummy text used in laying out print, graphic or web designs.
                                            </span>
                                        </div>
                                        <div className='left-description-text'>
                                            <span> “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.”
                                                The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.)
                                                that doesn't distract from the layout. A practice not without controversy, laying out pages with
                                                meaningless filler text can be very useful when the focus is meant to be on design, not content.
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <div className='right-content'>
                                    <div className='right-image-wrapper'> <img src={ImageAssets.about.ourStoryImage} alt="right our story" className='image-responsive' /> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='why-choose-us-section'>
                    <div className='why-choose-us-description'>
                        <div className='row no-gutters'>
                            <div className='col-12 col-lg-6 order-left-resp'>
                                <div className='why-choose-left-content order'>
                                    <div className='left-image-wrapper'> <img src={ImageAssets.about.whyChooseImage} alt="left why choose" className='image-responsive' /> </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6  order-right-resp'>
                                <div className='why-choose-right-content'>
                                    <div className='heading-class mb-30'> <h2 className='gray-color'>How <span className='text-primary-color'>We Work</span> </h2> </div>
                                    <div className='why-choose-description '>
                                        <div className='right-description-text mb-30'>
                                            <span> We have experts in various cities in India and here in Canada who strive to provide the most relevant and up-to-date
                                                information to students about the state of higher education in Canada. We provide individual attention to
                                                each and every applicant. We take pride in our ability to work closely with the community with utmost integrity,
                                                transparency, and honesty.
                                            </span>
                                        </div>
                                        <div className='right-description-text mb-30'>
                                            <span> We understand the challenges of moving to a new country as a student. Therefore, we work closely with
                                                the Indian diaspora residing in Canada and seek their guidance as well as their assistance to better
                                                serve the international students’ needs. Our aim is to become a robust support system for international
                                                students in Canada to make their transition as easy as possible.
                                            </span>
                                        </div>
                                        {/* <div className='right-description-text mb-3'>
                                            <span>The passage experienced a surge in popularity during the 1960s when Letraset used it on their
                                                dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software.
                                                Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get
                                                your own, or read on for the authoritative history of lorem ipsum. Lorem ipsum, or lipsum as it is
                                                sometimes known, is dummy text used in laying out print, graphic or web designs.
                                            </span>
                                        </div>
                                        <div className='right-description-text'>
                                            <span> Lorem ipsum, or lipsum as it is sometimes known,
                                                is dummy text used in laying out print, graphic or web designs.
                                                The passage is attributed to an unknown typesetter in the 15th century
                                                who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use
                                                in a type specimen book. It usually begins with:
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='our-partners-section'>
                    <div className='our-partners-content sub-section-padding'>
                        <div className='heading-class mb-30 text-center'> <h2 className='gray-color'> Our <span className='text-primary-color'> Partners </span> </h2> </div>
                        <div className='our-partner-slider position-relative'>
                            <div className='partners-university position-relative'>
                                <div className='d-flex flex-nowrap'>
                                    <OwlCarousel
                                        className=" owl-carousel owl-theme"
                                        {...clientReviews}
                                    >
                                        {appConfig.OUR_PARTNERS.map((eachPartner, index) => {
                                            return (
                                                <div className='partner-card' key={index}>
                                                    <div className='image-wrapper'> <img src={eachPartner.IMG} alt="" className='image-responsive uni-image' /> </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}
