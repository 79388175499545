import React from "react";
import { Link } from "react-router-dom";
import { UIRoutes } from "../../constants/FrontendRoutes";
import ImageAssets from "../../utils/ImageAssests";

export default function Footer() {
  const today = new Date().getFullYear();
  return (
    <div>
      <div className="footer-wrapper position-relative">
        <div className="footer-section">
          <div className="footer-icon-block">
            <div className="text-center logo-icon">
              <Link to={UIRoutes.HOME}>
                <img
                  src={ImageAssets.common.headerLogo}
                  alt="footer-logo"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-description-block">
              <div className="row no-gutters">
                <div className="col-sm-12 col-md-6 col-xl-6">
                  <div className="footer-text-heading">
                    <div>
                      <span className="text-primary-color">Quick Links</span>
                    </div>
                  </div>
                  <div className="footer-text-description">
                    <Link to={UIRoutes.ABOUTUS} className="text-white">
                      About Us
                    </Link>
                    <div className="pt-10">
                      <Link to={UIRoutes.CONTACTUS} className="text-white">
                        Contact Us
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className='col-6 col-sm-4 col-md-4 col-xl-4'>
                                    <div className='footer-text-heading'>
                                        <div><span className='text-primary-color'>Services</span></div>
                                    </div>
                                    <div className='footer-text-description'>
                                        <div><a href='/' className='text-white '>Admissions</a></div>
                                        <div className='pt-10'><a href='/' className='text-white'>Immigration</a></div>
                                        <div className='pt-10'><a href='/' className='text-white'>Visa</a></div>
                                    </div>
                                </div> */}
                <div className="col-sm-12 col-md-6 col-xl-6">
                  <div className="footer-text-heading">
                    <div>
                      <span className="text-primary-color">Resources</span>
                    </div>
                  </div>
                  <div className="footer-text-description">
                    {/* <div><a href='/' className='text-white'></a></div> */}
                    <div className="">
                      <Link to={UIRoutes.BLOG} className="text-white">
                        Blog
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className='col-6 col-sm-3 col-md-3 col-xl-3'>
                                    <div className='footer-text-heading'>
                                        <div><span className='text-primary-color'>Follow us on</span></div>
                                    </div>
                                    <div className='footer-social-media-icon d-flex'>
                                        <div className='social-media-icons'><img src={ImageAssets.common.facebook} alt='facebook' /></div>
                                        <div className='pl-15 social-media-icons'><img src={ImageAssets.common.linkdin} alt='linkdin' /></div>
                                        <div className='pl-15 social-media-icons'><img src={ImageAssets.common.twitter} alt='twitter' /></div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="post-footer-section">
        <div className="post-footer-text text-center">
          <span className="font-16 text-post-footer text-white">
            Copyrights © {today} All Rights Reserved, Powered by{" "}
            <span className="text-primary-color">
              New Horizons Student Services
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
