export const Labels = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  MIDDLE_NAME: "Middle Name",
  AGE: "Age",
  COMPLETED_EDUCATION: "Completed Education",
  WORK_EXPERIENCE_AND_FILED: "Work Experience and Filed",
  MARITAL_STATUS: "Marital Status",
  MARRIED: "Married",
  SINGLE: "Single",
  OTHER: "Other",
  SPOUSE_FIRST_NAME: "Spouse First Name",
  SPOUSE_LAST_NAME: "Spouse Last Name",
  SPOUSE_AGE: "Spouse Age",
  SPOUSE_EDUCATION: "Spouse Education",
  HIGH_SCHOOL: "High School",
  BACHELORS_DEGREE: "Bachelors Degree",
  MASTERS_DEGREE: "Masters Degree",
  EMAIL: "E-mail Address",
  PHONE_NUMBER: "Phone Number",
  MESSAGE: "Message",
  INSPECTION_DATE: "Inspection Date",
  LOCATION: "Location",
  PASSWORD: "Password",
  PASSPORT_NUMBER: "Passport number",
  CHOOSE_COUNTRY: "Please choose a country",
  STATUS: "Please choose a status",
  REFERRAL_SOURCE: "Please choose a referral source",
  COUNTRY_INT: "Country of interest",
  SERVICE_INT: "Service of interest",
  REFERENCE_NAME: "Reference Name",
  COMMENT: "Comment",
};

export const Messages = {
  LOGIN: "Login",
  CONTACT_US: "Contact Us",
  SUBMIT_NOW: "Submit Now",
  OK: "Ok",
  VIEW_ALL: "View All",
  LETS_CONNECT: "Let's Connect",
  READ_MORE: "Read More",
  BOOK_FOR_CONSULT: "Book For Consult",
  HOME: "Home",
  SERVICES: "Services",
  ABOUT: "About Us",
  BLOG: "Blog",
  SIGNUP: "Signup",
  SEND_OTP: "Send OTP",
};

export const InputIds = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  AGE: "age",
  EMAIL: "emailAddress",
  CONTACT: "contact",
  COMPLETED_EDUCATION: "completedEducation",
  WORK_EXPERIENCE_AND_FILED: "workExperienceAndFiled",
  MARITAL_STATUS: "maritalStatus",
  SPOUSE_FIRST_NAME: "spouseFirstName",
  SPOUSE_LAST_NAME: "spouseLastName",
  SPOUSE_EDUCATION: "spouseEducation",
  SPOUSE_AGE: "spouseAge",
  MESSAGE: "message",
  INSPECTIONDATE: "inspectionDate",
  REFERENCE_NAME: "referenceName",
  COMMENT: "comment",
};
