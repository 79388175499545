import ImageAssets from "../utils/ImageAssests"
export const appConfig = {
    API_VERSION: 'v1.0.0',
    MAX_LENGTH: {
        NAME: {
            MIN: 2,
            MAX: 20
        },
        MESSAGE: {
            MIN: 10,
            MAX: 100
        }
    },
    OUR_SERVICES: [
        { IMG: ImageAssets.home.studyAbroad, NAME: "ADMISSIONS" },
        { IMG: ImageAssets.home.profileAssessment, NAME: "CAREER COUNSELING" },
        { IMG: ImageAssets.home.immigration, NAME: "STUDENT VISA" },
        { IMG: ImageAssets.home.visaService, NAME: "IMMIGRATION" },
        { IMG: ImageAssets.home.consultation, NAME: "CONSULTATION" },
    ],
    OUR_PARTNERS: [
        { IMG: ImageAssets.about.uniOfToronto },
        { IMG: ImageAssets.about.reginaUni },
        { IMG: ImageAssets.about.lowrenceCollege },
        { IMG: ImageAssets.about.yorkUni },
    ],
    COMMON_BANNER: [
        { IMG: ImageAssets.common.aboutBannerImage, DESCRIPTION: "New Horizons Student Services provides honest expert advice to all potential aspirants seeking higher education in Canada. We take pride in our ability to work closely with the community with utmost integrity, transparency, and honesty." },
        { IMG: ImageAssets.common.contactBannerImage, DESCRIPTION: "New Horizons Student Services provides honest expert advice to all potential aspirants seeking higher education in Canada. We take pride in our ability to work closely with the community with utmost integrity, transparency, and honesty." },
        { IMG: ImageAssets.common.blogBannerImage, DESCRIPTION: "New Horizons Student Services provides honest expert advice to all potential aspirants seeking higher education in Canada. We take pride in our ability to work closely with the community with utmost integrity, transparency, and honesty." },
        { IMG: ImageAssets.common.serviceBannerImage, DESCRIPTION: "New Horizons Student Services provides honest expert advice to all potential aspirants seeking higher education in Canada. We take pride in our ability to work closely with the community with utmost integrity, transparency, and honesty." }
    ],
    MARITAL_STATUS: ['Single','Married','Other'],
    COMPLETED_EDUCATION:['12th','Diploma','Bachelor Degree','Master Degree','Other']
}